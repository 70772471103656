import React from 'react';

function SocialHeader() {
	return (
		<div className='socialHeaderContainer'>
			<div className='socialEmailIcon'>
				<a href='mailto:kleankingcarpet@att.net'>
					<svg
						width='20'
						height='20'
						viewBox='0 0 20 20'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'>
						<g clip-path='url(#clip0_12_3045)'>
							<path
								d='M18.2423 2.38281H1.75794C1.48736 2.38281 1.23404 2.44934 1.00486 2.55898L9.96107 11.5152L11.9685 9.58602C11.9685 9.58602 11.9686 9.58582 11.9686 9.58574C11.9687 9.58566 11.9688 9.58559 11.9688 9.58559L18.9957 2.55914C18.7665 2.44941 18.513 2.38281 18.2423 2.38281Z'
								fill='white'
							/>
							<path
								d='M19.8241 3.3878L13.2115 10.0001L19.8239 16.6125C19.9335 16.3833 20.0001 16.13 20.0001 15.8594V4.14061C20.0001 3.87019 19.9337 3.6169 19.8241 3.3878Z'
								fill='white'
							/>
							<path
								d='M0.176172 3.38765C0.0665234 3.61683 0 3.87015 0 4.14074V15.8595C0 16.1299 0.0664453 16.3833 0.176016 16.6124L6.78863 10.0001L0.176172 3.38765Z'
								fill='white'
							/>
							<path
								d='M12.383 10.8287L10.3754 12.7581C10.2609 12.8725 10.111 12.9298 9.9611 12.9298C9.81122 12.9298 9.66126 12.8725 9.54684 12.7581L7.61731 10.8286L1.00458 17.4409C1.2338 17.5506 1.48727 17.6172 1.75794 17.6172H18.2423C18.5129 17.6172 18.7662 17.5507 18.9954 17.4411L12.383 10.8287Z'
								fill='white'
							/>
						</g>
						<defs>
							<clipPath id='clip0_12_3045'>
								<rect width='20' height='20' fill='white' />
							</clipPath>
						</defs>
					</svg>
				</a>
			</div>
			<div className='socialEmail'>
				<a href='mailto:kleankingcarpet@att.net'>kleankingcarpet@att.net</a>
			</div>
			<div className='socialPhoneIcon'>
				<a href='tel:318-323-8722'>
					<svg
						width='15'
						height='15'
						viewBox='0 0 20 20'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M10.9706 2.27125C14.5111 2.71659 17.2833 5.48885 17.7287 9.02933C17.7955 9.59715 18.2742 10.0091 18.8309 10.0091C18.8754 10.0091 18.9199 10.0091 18.9645 9.99795C19.5768 9.92002 20.011 9.36334 19.9331 8.75099C19.3653 4.19736 15.7914 0.62348 11.2378 0.0556678C10.6255 -0.0111336 10.0688 0.423076 10.002 1.03542C9.92406 1.64777 10.3583 2.20445 10.9706 2.27125ZM11.3937 4.62044C10.8036 4.46457 10.1913 4.82084 10.0354 5.42205C9.87952 6.02327 10.2358 6.62448 10.837 6.78035C12.006 7.08096 12.919 7.99391 13.2307 9.17407C13.3643 9.67508 13.8097 10.0091 14.3107 10.0091C14.3997 10.0091 14.4999 9.99796 14.589 9.97569C15.1791 9.81982 15.5354 9.20747 15.3906 8.61739C14.8673 6.64675 13.342 5.12145 11.3937 4.62044ZM18.0404 13.6386L15.2125 13.3158C14.5333 13.2378 13.8653 13.4716 13.3866 13.9504L11.338 15.999C8.18723 14.3957 5.60425 11.8239 4.00102 8.66193L6.06073 6.60221C6.53947 6.12347 6.77327 5.45545 6.69534 4.77631L6.37246 1.97064C6.23886 0.846152 5.29251 0 4.15689 0H2.23079C0.972706 0 -0.0738448 1.04656 0.00408987 2.30465C0.594166 11.8127 8.19836 19.4058 17.6953 19.9959C18.9533 20.0738 19.9999 19.0273 19.9999 17.7692V15.8431C20.011 14.7186 19.1649 13.7722 18.0404 13.6386Z'
							fill='white'
						/>
					</svg>
				</a>
			</div>
			<div className='socialPhone'>
				<a href='tel:318-323-8722'>318-323-8722</a>
			</div>
			<div className='socialIcons'>
				<div className='socialIcon'>
					<a href='https://www.facebook.com/kleankingcarpet/' target='_'>
						<svg
							width='10'
							height='16'
							viewBox='0 0 10 16'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'>
							<path
								d='M3.02293 16L3 9H0V6H3V4C3 1.3008 4.67151 0 7.07938 0C8.23277 0 9.22406 0.08587 9.51294 0.12425V2.94507L7.84296 2.94583C6.53343 2.94583 6.27987 3.5681 6.27987 4.48124V6H10L9 9H6.27986V16H3.02293Z'
								fill='white'
							/>
						</svg>
					</a>
				</div>
			</div>
		</div>
	);
}

export default SocialHeader;
